import "./src/css/style.css";

import React from "react";
import ThemeContextProvider from "./src/context/themeContext";
import { FormspreeProvider } from "@formspree/react";
import { LazyMotion, domAnimation } from "framer-motion";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeContextProvider>
      <FormspreeProvider project="1580942286890466369">
        <LazyMotion strict features={domAnimation}>
          {element}
        </LazyMotion>
      </FormspreeProvider>
    </ThemeContextProvider>
  );
};

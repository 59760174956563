module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.hosting149841.a2e2b.netcup.net","siteUrl":"https://followthegrain.de","dev":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-pinterest/gatsby-browser.js'),
      options: {"plugins":[],"saveButton":{"round":true,"tall":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147560927-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"en","siteUrl":"https://followthegrain.de","i18nextOptions":{"defaultNS":"translation","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/projects","languages":["en","de"]},{"matchPath":"/about","languages":["en","de"]},{"matchPath":"/contact","languages":["en","de"]},{"matchPath":"/privacy","languages":["en","de"]},{"matchPath":"/works","languages":["en","de"]},{"matchPath":"/photography","languages":["en","de"]},{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/pictures/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/keyword/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/project/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang?/picture/:uid","getLanguageFromPath":true,"excludeLanguages":["en","de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FollowTheGrain","short_name":"followthegrain","start_url":"/","lang":"en","background_color":"#1c1c1c","theme_color":"#2DD4BF","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"maskable any"},"localize":[{"start_url":"/de/","lang":"de","name":"FollowTheGrain.de","short_name":"Follow the grain","description":"Follow the grain - analoge Fotografie Blog"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
